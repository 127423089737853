import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    props: {
        element: {
            type: Object,
            default: null
        },
    },

    data() {
        return {
            code: {},

            listShowMachineId:
                [
                    'BOOT_MACHINE',
                    'CHANGE_PACKAGE_MACHINE',
                    'SHUTDOWN_MACHINE',
                    'DOWNLOAD_REMOTE_DESKTOP',
                    'RENAME_IMAGE',
                    'REMOVE_IMAGE',
                    'CLONE_IMAGE',
                    'CREATE_IMAGE',
                    'SCHEDULER_IMAGE',
                    'HARDWARE_FIXATION',
                    'RESET_MACHINE',
                    'FIXED_RENTAL_SETTINGS',
                ],
            isShowMachineId: false,

            listShowPayment:
                [
                    'CREATE_PAYMENT'
                ],
            isShowPayment: false,

            source: null,

            fixedRental: false,
            packageId: null,
            masterName: null,
            masterDescription: null,
            operatingSystem: null,
            macAddress: null,
            FixedRentalType: {
                1: 'CREATE',
                2: 'CREATE IMAGE',
                3: 'RENEWAL',
                4: 'RENEWAL MACHINE',
            },
            FixedRentalPeriod: {
                1: "WEEK",
                2: "MONTH",
                3: "DAY",
            }
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
            userHistoryCode: state => state.auth.userHistoryCode,
        }),
        listServicePackDict() {
            return this.listServicePack.reduce(function (acc, cur, i) {
                acc[cur.id] = cur;
                return acc;
            }, {});
        },
    },

    created() {

    },

    watch: {
        element: function (newVal, oldVal) {
            let arrUserHistoryCode = this.userHistoryCode.slice();
            this.code = Object.assign({}, arrUserHistoryCode.find(x => parseInt(x.id) === parseInt(newVal.action)));
            if (this.code) {
                //Color
                this.getStatusColorLog();

                //Source
                this.getSourceRequest();

                //Target
                if (this.listShowMachineId.includes(this.code.type)) {
                    this.isShowMachineId = true;
                }
                this.proccessingMachineLog();
                if (this.listShowPayment.includes(this.code.type)) {
                    this.isShowPayment = true;
                }
            }
        },
    },

    mounted() {
        let arrUserHistoryCode = this.userHistoryCode.slice();
        this.code = Object.assign({}, arrUserHistoryCode.find(x => parseInt(x.id) === parseInt(this.element.action)));
        if (this.code) {
            //Color
            this.getStatusColorLog();

            //Source
            this.getSourceRequest();

            //Target
            if (this.listShowMachineId.includes(this.code.type)) {
                this.isShowMachineId = true;
            }
            this.proccessingMachineLog();
            if (this.listShowPayment.includes(this.code.type)) {
                this.isShowPayment = true;
            }
        }
    },

    methods: {

        //Status color log
        getStatusColorLog() {
            if (this.code.level === 1) {
                this.code.color = '#66BB6A';
                return;
            }

           if (this.code.level >= 25) {
                this.code.color = 'red';
                return;
           }

            this.code.color = '#FFA726';
            return;
        },

        //Source request
        getSourceRequest() {
            try {
                if (this.element.deviceInformation && JSON.parse(this.element.deviceInformation)) {
                    let sourceRequest = JSON.parse(this.element.deviceInformation).sourceRequest;
                    switch (sourceRequest) {
                        case "WEB":
                            this.source = "WEB";
                            break;
                        case "GPUHUBSYNC":
                            this.source = "SYNC";
                            break;
                        case "License":
                            this.source = "LIC";
                            break;
                        case "HELPDESK":
                            this.source = "DESK";
                            break;
                        case "API":
                            this.source = "API";
                            break;
                        default:
                            this.source = sourceRequest;
                            break;
                    }
                }
            } catch (error) { console.log(error) }
        },

        // Proccessing machine log
        proccessingMachineLog() {
            if (this.element.content.TargetImage) {
                this.fixedRental = this.element.content.TargetImage.FixedRental;
                this.packageId = this.element.content.TargetImage.PackageId;
                this.masterName = this.element.content.TargetImage.MasterName;
                this.masterDescription = this.element.content.TargetImage.MasterDescription;
                this.operatingSystem = this.element.content.TargetImage.OperatingSystem;
                this.macAddress = this.element.content.TargetImage.MacAddress;
            }
            
            switch (this.code.id) {
                case 80001:
                case 80003:
                case 80011:
                case 80012:
                case 80013:
                case 80017:
                case 80018:
                case 80019:
                case 80098:
                    this.packageId = this.element.content.PackageId;
                    this.operatingSystem = this.element.content.OperatingSystem;
                    break;


                case -100000:
                case -100001:
                case -100002:
                case -100003:
                case -100004:
                case -100005:
                case 100000:
                case 100001:
                case 100002:
                case 100003:
                case 100004:
                case 100098:
                case 100099:
                    this.code.name = "Shutdown machine";
                    if (this.element.content.type === 1 || this.element.content.Type === 1) {
                        this.code.name = "Shutdown machine"
                    }
                    
                    if (this.element.content.type === 2 || this.element.content.Type === 2) {
                        //this.code.name = "Shutdown machine to clone";
                        this.$set(this.code, 'name', "Shutdown machine to clone");
                    }
                    break;
                case 410000:
                    this.element.rentalTargetMachineId = null;
                    break;
                case 500000:
                    this.element.rentalTargetMachineId = this.element.content.MachineId;
                    break;
            }
        }
    }
}