import baseComponent from "@/scripts/baseComponent";
import usersApi from '@/api/common/users';
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
const queryString = require('query-string');
import ColumnUser from "@/views/components/column/User.vue";
import ColumnBalance from "@/views/components/column/Balance.vue";
import ColumnIpDetails from "@/views/components/column/IpDetails.vue";
import ColumnContentHistory from "@/views/components/column/ColumnContentHistory.vue";
import ColumnIpAnalysis from "@/views/components/column/IpAnalysis.vue";

export default {

    components: {
        ColumnUser,
        ColumnBalance,
        ColumnIpDetails,
        ColumnContentHistory,
        ColumnIpAnalysis
    },

    extends: baseComponent,

    props: {
        username: {
            type: String,
            default: null
        },
        machineId: {
            type: Number,
            default: null
        },
        cardHeight: {
            type: String,
            default: null
        },
        minInfo: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        username(newVal) {
            if (this.username !== null) this.loadInitData();
        },
        machineId(newVal) {
            if (this.machineId !== null) this.loadInitData();
        }
    },
    data() {
        return {
            listData: {},
            isFull: true,
            pagination: {
                querySearch: '',
                username: '',
                action: '',
                orderBy: 'CREATED_AT',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
            action: '',
            actionList: [],
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {
        this.$userLogStatus.forEach(element => {
            if (this.actionList.find(x => x.label === element.name) !== undefined) {
                this.actionList.find(x => x.label === element.name).value = `${this.actionList.find(x => x.label === element.name).value},${element.status}`
            } else {
                this.actionList.push({
                    label: element.name,
                    value: element.status.toString(),
                    icon: element.icon,
                })
            }
        });
    },

    mounted() {
        this.loadInitData();
    },

    methods: {
        loadInitData() {
            if (this.username) {
                this.pagination.username = this.username;
                this.isFull = false;
            } else {
                this.isFull = true
            }
            this.getData(this.pagination);
        },
        //Context menu action
        contextMenuAction(command) {
            switch (command.type) {
                case "user-history-add-restricted-list":
                    EventBus.$emit("openPopup", command);
                    break;
                case "user-report-user":
                    command.username = command.record.username;
                    EventBus.$emit("openPopup", command);
                    break;
                case "user-report-ip":
                    command.ip = command.record.ip;
                    EventBus.$emit("openPopup", command);
                    break;
            }
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.feedStatusLoading(0);
            if (this.machineId != null && request !== undefined) {
                request.machineId = this.machineId;
            }
            usersApi.getUsersHistory(queryString.stringify(request))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = responseData.pagingItem;
                        this.listData = responseData.data;
                        this.listData.forEach(element => {
                            element.columnUser = this.mapColumnUser(element);
                            element.columnBalance = this.mapColumnBalance(element);
                            element.columnIpDetails = this.mapColumnIpDetails(element);
                            if (this.isValidJson(element.content)) element.content = JSON.parse(element.content);
                            else element.content = {};
                            if (this.isValidJson(element.deviceInformation)) element.deviceInformationObject = JSON.parse(element.deviceInformation);
                            else element.deviceInformationObject = {};
                            //console.log('element.deviceInformationObject', element.deviceInformationObject);
                        });
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        this.feedStatusLoading(1);
                    } catch (error) {
                        console.log("getData -> error", error);
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        },

        sortList() {

        },

        // Search list
        searchList() {
            this.pagination.action = this.action.join();
            this.pagination.pageIndex = 1;
            this.getData(this.pagination);
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            let request = {
                querySearch: this.pagination.querySearch,
                username: this.pagination.username,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                action: this.pagination.action,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },

        // Pagination page change
        handleCurrentChange(element) {
            let request = {
                querySearch: this.pagination.querySearch,
                username: this.pagination.username,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: element,
                pageSize: this.pagination.pageSize,
                action: this.pagination.action,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },

        // Map to component
        mapColumnUser(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    registerAt: element.userCreatedAt,
                    region: element.region,
                }
            } catch {
                result = {};
            }
            return result;
        },

        mapColumnBalance(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    totalRecharge: element.totalRecharge,
                    balance: element.balance,
                    totalImage: element.totalImage,
                    imageThreshold: element.imageThreshold,
                    totalStorage: element.totalStorage,
                    storageThreshold: element.storageThreshold,
                }
            } catch {
                result = {};
            }
            return result;
        },

        mapColumnIpDetails(element) {
            let result = {};
            try {
                if (element.ip === undefined || element.ip === null || element.ip === '') {
                    result.ip = null;
                } else {
                    result.ip = element.ip;
                }
                result.ipType = element.ipType;
                result.ipDescription = element.ipDescription;
                if (element.ipDetails !== undefined && element.ipDetails !== null && element.ipDetails !== '') {
                    let ipDetails = JSON.parse(element.ipDetails);
                    result.ipDetails = ipDetails;
                } else {
                    result.ipDetails = null;
                }
                if (element.deviceInformation !== undefined &&
                    element.deviceInformation !== null &&
                    element.deviceInformation !== '') {
                    let deviceInformation = JSON.parse(element.deviceInformation);
                    if(deviceInformation.resolutionDevice) result.resolution = deviceInformation.resolutionDevice;
                    if(deviceInformation.timeZone) {
                        let timeZone = deviceInformation.timeZone;
                        if (timeZone === '-0') {
                            timeZone = '+ 0'
                        } else {
                            timeZone = timeZone.replace("+", "+ ").replace("-", "- ");
                        }
                        result.timeZone = timeZone;
                    }

                    if (deviceInformation.sourceRequest) {             
                        let windowsRegex = /(Microsoft )(Windows )(\d+).([\d.]+)/g;           
                        switch(deviceInformation.sourceRequest.toLowerCase()){
                            case 'web':
                                if(JSON.parse(element.deviceInformation).browserDetails !== undefined &&
                                JSON.parse(element.deviceInformation).browserDetails !== null &&
                                JSON.parse(element.deviceInformation).browserDetails !== ''){
                                    let browserDetails = JSON.parse(deviceInformation.browserDetails);
                                    let browserInfo = this.getBrowserInfo({
                                        nAgt: browserDetails.userAgent,
                                        appVersion: browserDetails.appVersion,
                                    });
                                    result.browserName = browserInfo.browserName;
                                    result.fullVersion = browserInfo.fullVersion;
                                    result.systemOS = browserInfo.systemOS;
                                    result.versionOS = browserInfo.versionOS;
                                }
                                break;
                            case 'gpuhubsync':
                                result.resolution = deviceInformation.pc_screen;
                                result.browserName = null;
                                result.fullVersion = null;
                                result.systemOS = deviceInformation.os_name;
                                result.versionOS = deviceInformation.os_version;
                                break;
                            case 'app':
                                var arrGroup = windowsRegex.exec(deviceInformation.os);
                                result.resolution = deviceInformation.resolutionDevice;
                                result.browserName = null;
                                result.fullVersion = null;
                                result.systemOS = arrGroup[2].trim();
                                result.versionOS = arrGroup[3].trim();
                                break;
                        }
                    }
                } else {
                    result.timeZone = null;
                    result.resolution = null;
                }
            } catch (error) {
                console.log("mapColumnIpDetails -> error", error)
            }
            return JSON.parse(JSON.stringify(result));
        },
    }
}