import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import usersApi from '@/api/common/users';
import EventBus from "@/event/EventBus";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    props: {
        element: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            totalRecord: '...',
            totalUserSameIp: '...'
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
            userHistoryCode: state => state.auth.userHistoryCode,
        }),
    },

    created() {

    },

    mounted() {
        this.getIpAnalysis();
    },

    methods: {

        //User same IP view
        userSameIpView() {
            if (this.totalUserSameIp > 0) {
                EventBus.$emit("openPopup", {
                    type: 'user-same-ip-list',
                    data: {
                        type: 2,
                        data: this.element.id
                    },
                });
            }
        },

        // Get IP analysis
        getIpAnalysis() {
            usersApi.getIpAnalysis(this.element.id)
                .then((res) => {
                    try {
                        this.totalRecord = res.data.totalRecord;
                        this.totalUserSameIp = res.data.totalUserSameIp;
                    } catch (error) {
                        console.log(error);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}