var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row justify-content-end mt-1",style:(`${
    _vm.element.ipType === 1 ? 'filter: grayscale(100%); opacity: 0.4' : ''
  }`)},[_c('el-tooltip',{attrs:{"effect":"light","content":`Number of different users using IP: `,"placement":"top"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center",staticStyle:{"color":"darkorange","font-size":"0.85rem"},style:(`${
        _vm.totalUserSameIp === 0 || _vm.totalUserSameIp === '...'
          ? 'filter: grayscale(100%); opacity: 0.4; '
          : 'cursor: pointer;'
      }`),on:{"click":function($event){return _vm.userSameIpView()}}},[_c('div',[_c('i',{staticClass:"el-icon-copy-document",staticStyle:{"font-size":"0.75rem"}})]),_c('div',{staticClass:"ml-1"},[_c('span',{staticStyle:{"color":"red","font-size":"1.1rem"}},[_vm._v(_vm._s(_vm.totalUserSameIp))]),_c('span',{staticClass:"ml-1"},[_vm._v("user")])])])]),_c('el-tooltip',{attrs:{"effect":"light","content":`Number of activities from IP: `,"placement":"top"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center ml-4",staticStyle:{"color":"#9e9e9e","cursor":"pointer","font-size":"0.85rem"},style:(`filter: ${
        _vm.totalRecord === 0 || _vm.totalRecord === '...'
          ? 'grayscale(100%); opacity: 0.4'
          : 'none'
      }`)},[_c('div',[_c('i',{staticClass:"el-icon-tickets",staticStyle:{"font-size":"0.75rem"}})]),_c('div',{staticClass:"ml-1"},[_c('span',{staticStyle:{"color":"#007bff","font-size":"1.1rem"}},[_vm._v(_vm._s(_vm.totalRecord))]),_c('span',{staticClass:"ml-1"},[_vm._v("record")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }